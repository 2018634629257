











































import Vue from "vue";
import { mapGetters } from "vuex";
import { CANDIDATE_JOBS } from "@/store/modules/candidates/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "SearchJob",
  data() {
    return {
      form: false,
      search_rules: [
        (value: string) =>
          !!value || this.$t("candidate.jobs.field-required-rule")
      ]
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("candidate", {
      candidate_jobs: CANDIDATE_JOBS
    }),
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  methods: {
    submit_form() {
      const title = this.candidate_jobs.matching_jobs.job_title;
      if (title) this.$emit("search_job_title", title);
    },
    reset_jobs() {
      this.$emit("reset_jobs", null);
    }
  }
});
